import React, { useEffect } from 'react';
import { Box, Container, Typography, Paper, Grid, List, ListItem, ListItemText, ListItemIcon, 
  Divider, Chip, Fade, Button, Link } from '@mui/material';


  const Resources = () => {

  return (
    <Box sx={{ overflow: 'hidden' }}>
    <Container maxWidth= "large" sx={{ paddingTop: 4 }}>
<Paper>
    Hello
</Paper>

    </Container>

    </Box>

  );
  };

  export default Resources;